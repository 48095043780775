import env from '@js/utils/env';
import SectionStyles from '@js/modules/SectionStyles';

import { gsap } from 'gsap';

const CSS_OPENED = '_open';
const CSS_CLOSED = 'hidden';

class Accordion {
    constructor() {
        this.reducedMotion = env.reducedMotion;

        document.querySelectorAll('[data-accordion]').forEach((accordion) => {
            this._initAccordion(accordion);
        });
    }
    _initAccordion(accordion) {
        const items = accordion.querySelectorAll('[data-accordion-item]');

        // Loop through all accordion items
        items.forEach((item) => {
            const header = item.querySelector('[data-accordion-header]');
            const panel = item.querySelector('[data-accordion-panel]');

            let opened = false;

            header.addEventListener('click', (e) => {
                opened = !opened;

                if (opened) {
                    // Open
                    item.classList.add(CSS_OPENED);
                    panel.classList.remove(CSS_CLOSED);
                    header.classList.add(CSS_OPENED);

                    // WCAG
                    header.setAttribute('aria-expanded', true);

                    const h = panel.offsetHeight;
                    gsap.fromTo(
                        panel,
                        { height: 0, overflow: 'hidden' },
                        {
                            height: h,
                            duration: this.reducedMotion ? 0 : .8,
                            clearProps: 'all',
                            onComplete: () => {
                                // Section style rebuild
                                SectionStyles.update(true);
                            }
                        }
                    );
                }
                else {
                    // Close
                    gsap.set(panel, {
                        overflow: 'hidden',
                    });
                    gsap.to(panel, {
                        height: 0,
                        duration: this.reducedMotion ? 0 : .45,
                        clearProps: 'all',
                        onComplete: () => {
                            panel.classList.add(CSS_CLOSED);

                            item.classList.remove(CSS_OPENED);
                            header.classList.remove(CSS_OPENED);

                            // WCAG
                            header.setAttribute('aria-expanded', false);

                            setTimeout(() => {
                                console.log('Call update');
                                // Section style rebuild
                                SectionStyles.update(true);
                            }, 500);
                        },
                    });
                }
            });
        });
    }
}

export default new Accordion();
