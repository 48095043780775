class RolloverReveal {
    constructor() {
        this.attrName = 'data-rollover-reveal';

        // Uncomment this to initiate when ready
        // this.start();
    }
    start() {
        document.querySelectorAll('[' + this.attrName + ']').forEach((el) => {
            this._init(el);
        });
    }
    _init(target) {
        const attrs = target.getAttribute(this.attrName).split(',');

        const revealElement = attrs[0] ? attrs[0].trim() : null;
        const elementBoundary = attrs[1] ? attrs[1].trim() === 'true' : false;
		const reverseFlag = attrs[2] ? attrs[2].trim() === 'true' : false;

        const element = document.querySelector(revealElement) ?? target.querySelector('[data-rollover-reveal-element]');

        if (!element) {
            console.warn('No [data-rollover-reveal-element] element founded');
            return;
        }

        gsap.set(element, {
            alpha: 0,
            display: 'block',
            'will-change': 'transform'
        });

        let bBox;
        let mouseX;
        let mouseY;

        const moveelement = (immediate) => {
            // let x = mouseX - bBox.left;
            // let y = mouseY - bBox.top;
            let x = mouseX;
            let y = mouseY;

            if (reverseFlag){
                x = bBox.width - x;
                y = bBox.height - y;
            }
            gsap.to(element, {
                x: x,
                y: y,
                duration: immediate ? 0 : .05
            });
        };

        target.addEventListener('mouseenter', (e) => {
            bBox = target.getBoundingClientRect();

            gsap.to(element, {
                alpha: 1,
                duration: .15
            });

            mouseX = e.clientX;
            mouseY = e.clientY;

            moveelement(true);
        });
        target.addEventListener('mouseleave', (e) => {
            gsap.to(element, {
                alpha: 0,
                duration: .35
            });
        });
        target.addEventListener('mousemove', (e) => {
            mouseX = e.clientX;
            mouseY = e.clientY;

            moveelement();
        });
    }
}

export default new RolloverReveal();
