import PageScrollingManager from '@js/modules/PageScrollingManager';

import {gsap} from 'gsap';

class Parallax {
    constructor() {
        this.parallaxTargets = [];
    }
    start() {
        this._setup();
    }
    clear() {
        this.parallaxTargets.forEach((target) => {
			PageScrollingManager.unwatch(target);
		});
    }
    _setup(accordion) {
        const attrSectionName = 'data-parallax-section'
            attrName = 'data-parallax';

        document.querySelectorAll('[' + attrSectionName + ']').forEach((section) => {
			const parallaxElements = document.querySelectorAll('[' + attrName + ']');
			const totalParallaxElements = parallaxElements.length;
			const parallaxSettings = [];

			for (let k = 0; k < totalParallaxElements; k++) {
				const el = parallaxElements[k];
				let power = el.getAttribute(attrName);
				power = Number.parseFloat(power);
				power = Number.isNaN(power) ? 50 : power;
				parallaxSettings[k] = power;
			}

			this.parallaxTargets.push(
				PageScrollingManager.watch(section, null, (ratio) => {
					for (let k = 0; k < totalParallaxElements; k++) {
						const el = parallaxElements[k];
						const power = parallaxSettings[k];

						gsap.set(el, { y: power * ratio + '%' });
					}
				})
			);
		});
    }
}

export default new Parallax();
