class SectionStyles {
	constructor() {

	}
    start() {
        this.rebuild();

		window.addEventListener('scroll', e => {
			this.update();
		});
		window.addEventListener('resize', e => {
			this.update(true);
		})
    }
	rebuild(){
		this.sections = [];

		Array.from(document.querySelectorAll('[data-section-style]')).forEach(el => {
			this.sections.push({
				element: el,
				className: el.getAttribute('data-section-style')
			})
		})

		if (this.targets){
			this.targets.forEach(targetData => {
				if(targetData.appliedClass){
					targetData.element.classList.remove(targetData.appliedClass)
				}
			})
		}
		this.targets = [];
		Array.from(document.querySelectorAll('[data-section-style-target]')).forEach(el => {
			this.targets.push({
				element: el,
			})
		})

		this.update(true);
	}
	update(forceUpdateGeometry = false){
		const scrollTop = window.pageYOffset;
		const totalSections = this.sections.length;

		if (forceUpdateGeometry){
			this.sections.forEach(sectionData => {
				const bBox = sectionData.element.getBoundingClientRect()
				sectionData.bBox = {
					top: bBox.top + scrollTop,
					bottom: bBox.bottom + scrollTop
				}
			})
			this.targets.forEach(targetData => {
				const bBox = targetData.element.getBoundingClientRect()
				targetData.bBox = {
					top: bBox.top + scrollTop,
					bottom: bBox.bottom + scrollTop
				}
			})
		}

		//let i = false;
		this.targets.forEach(targetData => {
			const bBox = targetData.element.getBoundingClientRect();

			const targetTop = bBox.top;
			const targetBottom = bBox.bottom;

			let foundedClass = '';

			for (let k=0; k<totalSections; k++){
				const sectionData = this.sections[k];

				const sectionTop = sectionData.bBox.top - scrollTop;
				const sectionBottom = sectionData.bBox.bottom- scrollTop;

				if (sectionTop <= targetTop && sectionBottom >= targetTop){
					foundedClass = sectionData.className;
				}

			}

			if (targetData.appliedClass !== foundedClass){
				if (targetData.appliedClass){
					targetData.element.classList.remove(targetData.appliedClass);
					targetData.appliedClass = null;
				}
				if (foundedClass !== ''){
					targetData.appliedClass = foundedClass;
					targetData.element.classList.add(targetData.appliedClass);
				}
			}
		})
	}
}

export default new SectionStyles();
