import env from '@js/utils/env';
import PageScrolling from '@js/modules/PageScrolling';

import { gsap } from 'gsap';

const HTML_CLASSLIST = document.documentElement.classList;
const CSS_OPENED = '_nav-open';

class Navigation {
    constructor() {
        this.opened = false;
        this.reducedMotion = env.reducedMotion;

        this.element = document.getElementById('nav');
        this.navBtns = this.element.querySelectorAll('nav a');
        this.socialIcons = this.element.querySelectorAll('.social-btns .icon');

        this.opener = document.getElementById('nav-btn');
        if (this.opener) {
            this.opener.addEventListener('click', (e) => {
                e.preventDefault();

                this.toggle();
            });
        }

        // Keydown event
        window.addEventListener('keydown', (e) => {
            // ESC key
            if (this.opened && e.keyCode === 27) {
                this.close();
            }
        });
    }
    open() {
        if (!this.opened) {
            this.opened = true;

            // Update accessability tags
            this.opener.setAttribute('aria-expanded', true);

            HTML_CLASSLIST.add(CSS_OPENED);

            PageScrolling.lock(this.element);

            // Animate nav
            gsap.to(
                this.element,
                {
                    duration: this.reducedMotion ? 0 : .4,
                    y: 0,
                    onComplete: () => {
                        let btnDelay = 0;
                        let socialBtnDelay = .3;

                        // Animate buttons
                        this.navBtns.forEach((btn, index) => {
                            gsap.to(
                                btn,
                                {
                                    alpha: 1,
                                    y: 0,
                                    duration: this.reducedMotion ? 0 : .5,
                                    delay: this.reducedMotion ? 0 : btnDelay
                                }
                            );

                            btnDelay = btnDelay + .09;
                        });

                        // Animate social buttons
                        this.socialIcons.forEach((btn, index) => {
                            gsap.to(
                                btn,
                                {
                                    alpha: 1,
                                    duration: this.reducedMotion ? 0 : .5,
                                    delay: this.reducedMotion ? 0 : socialBtnDelay
                                }
                            );

                            socialBtnDelay = socialBtnDelay + .1;
                        });
                    }
                }
            );
        }
    }
    close() {
        if (this.opened) {
            this.opened = false;

            // Update accessability tags
            this.opener.setAttribute('aria-expanded', false);

            HTML_CLASSLIST.remove(CSS_OPENED);

            // Animate nav
            gsap.to(
                this.element,
                {
                    duration: this.reducedMotion ? 0 : .4,
                    y: '-100%',
                    onComplete: () => {
                        PageScrolling.unlock(this.element);

                        // Animate buttons
                        this.navBtns.forEach((btn, index) => {
                            gsap.to(
                                this.navBtns,
                                {
                                    duration: 0,
                                    alpha: 0,
                                    y: '-20px'
                                }
                            );
                        });
                    }
                }
            );

            // Animate social buttons
            gsap.to(
                this.socialIcons,
                {
                    alpha: 0,
                    duration: this.reducedMotion ? 0 : .2
                }
            );
        }
    }
    toggle() {
        if (this.opened) {
            this.close();
        }
        else {
            this.open();
        }
    }
}

export default new Navigation();
