import env from '@js/utils/env';

class ParticleConnections {
    constructor() {
        (function () {
            if (window.ParticleConnections) {
                return;
            }
            window.ParticleConnections = function (container, options) {
                let redudceParticleAnimation = env.reducedMotion;

                // Default options
                this.options = {
                    itemsSelector: '.item',
                    effectDistance: redudceParticleAnimation ? 0 : 80,
                    moveDistance: redudceParticleAnimation ? 0 : 50,
                    smoothing: redudceParticleAnimation ? 0 : 10,
                    wanderingSpeed: redudceParticleAnimation ? 0 : 1,
                    wanderingDistance: redudceParticleAnimation ? 0 : 10,
                    lineColor: '#ff0000',
                    lineWidth: 1
                };

                // Pass in custom options
                this.options = {
                    ...this.options,
                    ...options
                };

                this.container = container;
                this.started = true;

                this.useCanvas = !!this.options.canvas;

                if (this.useCanvas) {
                    this.canvas = this.options.canvas;
                    this.context = this.canvas.getContext('2d');
                }

                const items = Array.from(
                    this.container.querySelectorAll(this.options.itemsSelector)
                );
                this.totalItems = items.length;
                this.items = [];

                for (let k = 0; k < this.totalItems; k++) {
                    const item = items[k];
                    const connections = [];

                    if (item.hasAttribute('data-connections')) {
                        const connectionsArray = item
                            .getAttribute('data-connections')
                            .split(',');

                        connectionsArray.forEach((connection) => {
                            connection = Number.parseInt(connection.trim());

                            if (connection !== '') {
                                if (!Number.isNaN(connection)) {
                                    if (
                                        connection >= 0 &&
                                        connection < this.totalItems &&
                                        connection !== k
                                    ) {
                                        connections.push(connection);
                                    }
                                }
                            }
                        });
                    }

                    this.items.push({
                        element: item,
                        bBox: null,
                        x: 0,
                        y: 0,
                        relX: 0,
                        relY: 0,
                        moveX: 0,
                        moveY: 0,
                        renderable: !!connections.length,
                        connections: connections,
                        state: false,
                        appliedMoveX: 0,
                        appliedMoveY: 0,
                        wSpeedX:
                            ((Math.random() - 0.5) * this.options.wanderingSpeed) / 60,
                        wSpeedY:
                            ((Math.random() - 0.5) * this.options.wanderingSpeed) / 60,
                        wX: Math.random(),
                        wY: Math.random()
                    });
                }

                // Events
                this._renderShim = () => {
                    this.render();
                };

                this.update();
                window.addEventListener('resize', () => {
                    this.update();
                });

                document.body.addEventListener('mousemove', (e) => {
                    this.mouseX = e.clientX - this.bBox.left;
                    this.mouseY = e.clientY - this.bBox.top;

                    this.relMouseX = this.mouseX / this.bBox.width;
                    this.relMouseY = this.mouseY / this.bBox.height;
                });

                requestAnimationFrame(this._renderShim);
            };
            window.ParticleConnections.prototype = {
                start() {
                    this.started = true;
                },
                stop() {
                    this.started = false;
                },
                update() {
                    this.bBox = this.container.getBoundingClientRect();

                    for (let k = 0; k < this.totalItems; k++) {
                        const item = this.items[k];

                        item.element.style.transform = '';

                        const bBox = item.element.getBoundingClientRect();
                        item.bBox = bBox;
                        item.x = bBox.left - this.bBox.left;
                        item.y = bBox.top - this.bBox.top;
                        item.relX = item.x / this.bBox.width;
                        item.relY = item.y / this.bBox.height;
                    }

                    if (this.useCanvas) {
                        this.canvas.width = this.canvas.offsetWidth;
                        this.canvas.height = this.canvas.offsetHeight;
                    }

                    this.render(true);
                },
                render(skipRequestFrame) {
                    !skipRequestFrame && requestAnimationFrame(this._renderShim);

                    if (!this.started) {
                        return;
                    }

                    for (let k = 0; k < this.totalItems; k++) {
                        const item = this.items[k];

                        const dx = item.x - this.mouseX;
                        const dy = item.y - this.mouseY - window.pageYOffset;

                        let newMoveX = 0;
                        let newMoveY = 0;

                        let catched = false;

                        if (
                            Math.abs(dx) <= this.options.effectDistance &&
                            Math.abs(dy) <= this.options.effectDistance
                        ) {
                            const distance = Math.sqrt(dx * dx + dy * dy);

                            if (distance <= this.options.effectDistance) {
                                const power = distance / this.options.effectDistance;

                                newMoveX =
                                    (-dx / distance) *
                                    power *
                                    this.options.moveDistance;
                                newMoveY =
                                    (-dy / distance) *
                                    power *
                                    this.options.moveDistance;

                                catched = true;
                            }
                        }

                        if (item.state !== catched) {
                            item.state = catched;

                            if (item.state) {
                                item.element.classList.add('_active');
                            }
                            else {
                                item.element.classList.remove('_active');
                            }
                        }

                        item.moveX += (newMoveX - item.moveX) / this.options.smoothing;
                        item.moveY += (newMoveY - item.moveY) / this.options.smoothing;

                        // Wandering
                        item.wX += item.wSpeedX;
                        item.wY += item.wSpeedY;

                        const wx = Math.cos(item.wX) * this.options.wanderingDistance;
                        const wy = Math.sin(item.wY) * this.options.wanderingDistance;

                        // Moving
                        const moveX = Math.round((item.moveX + wx) * 10) / 10;
                        const moveY = Math.round((item.moveY + wy) * 10) / 10;

                        if (
                            item.appliedMoveX !== moveX ||
                            item.appliedMoveY !== moveY
                        ) {
                            item.appliedMoveX = moveX;
                            item.appliedMoveY = moveY;

                            item.realX = item.x + moveX;
                            item.realY = item.y + moveY;

                            item.element.style.transform =
                                'translate3d(' + moveX + 'px, ' + moveY + 'px, 0px)';
                        }
                    }

                    if (this.useCanvas) {
                        this.context.clearRect(
                            0,
                            0,
                            this.canvas.width,
                            this.canvas.height
                        );
                        this.context.strokeStyle = this.options.lineColor;
                        this.context.lineWidth = this.options.lineWidth;

                        for (let k = 0; k < this.totalItems; k++) {
                            const item = this.items[k];

                            if (item.renderable) {
                                item.connections.forEach((connection) => {
                                    this.context.beginPath();
                                    this.context.moveTo(item.realX, item.realY);

                                    const connectedItem = this.items[connection];

                                    this.context.lineTo(
                                        connectedItem.realX,
                                        connectedItem.realY
                                    );
                                    this.context.stroke();
                                });
                            }
                        }
                    }
                }
            };
        })();
    }
}

export default new ParticleConnections();
