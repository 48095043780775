import gsap from 'gsap';

const getAttributeValue = (a, d) => {
	a = (a || '').trim();
	a = Number.parseFloat(a);

	if (Number.isNaN(a)) {
		return d;
	}
	return a;
};

class Switchers {
	constructor() {
		// Uncomment to have it load when ready
		//this.update();
	}
	init(el, debug) {
		if (el.switchersInited) {
			return;
		}

		// Attributes
		const attribute = el.getAttribute('data-switch').split(',');
		const duration = getAttributeValue(attribute[0], 0.5);
		const delay = getAttributeValue(attribute[1], 1);
		const easing = (attribute[2] || '').trim();
		const fadeEasing = (attribute[3] || '').trim();
		const delayAppear = getAttributeValue(attribute[4], 0);
		const distance = getAttributeValue(attribute[5], 100);
		const maxRepeats = getAttributeValue(attribute[6], -1);
		const mouseoverElId = (attribute[7] || '').trim();


		// Set the mouseover var to nothing
		let mouseoverEl = null;

		// Test if an element ID has been passed in
		if (mouseoverElId) {
			// Get the element
			mouseoverEl = document.getElementById(mouseoverElId);

			// If the element does not exist in the DOM default to the element
			if (!mouseoverEl) {
				mouseoverEl = null;

				if (debug) {
					console.log('No element in DOM with the ID ' + mouseoverElId);
				}
			}
		}

		if (debug) {
			console.log(el, duration, delay, easing, mouseoverElId);
		}

		const children = el.children;

		let index,
			repeatsCounter,
			needWidth,
			animating;

		const resetVars = () => {
			index = -1;
			repeatsCounter = 0;
			needWidth;
			animating = false;
		};

		const next = (immediate = false, repeat = false) => {
			repeatsCounter++;

			animating = true;

			if (maxRepeats > 0) {
				if (repeatsCounter > maxRepeats) {
					// Finish

					// Reset vars
					resetVars();

					// Don't animate anymore
					return;
				}
			}

			index++;
			index = index > children.length - 1 ? 0 : index;

			const tweenTime = immediate ? 0 : duration;
			const showChild = children[index];

			gsap.to(children, {
				y: '-' + distance + '%',
				duration: tweenTime,
				ease: easing,
			});
			gsap.to(children, {
				alpha: 0,
				duration: tweenTime,
				ease: fadeEasing,
			});

			// Remove active class from all children
			for (let c of children) {
	            c.classList.remove('_active');
	        }

			// Y animation
			gsap.fromTo(
				showChild,
				{
					y: distance + '%',
				},
				{
					y: '0%',
					duration: tweenTime,
					ease: easing,
					delay: immediate ? 0 : delayAppear,
				}
			);
			// Opacity animation
			gsap.fromTo(
				showChild,
				{
					alpha: 0,
				},
				{
					alpha: 1,
					duration: tweenTime,
					ease: fadeEasing,
					delay: immediate ? 0 : delayAppear,
				}
			);

			needWidth = showChild.offsetWidth;
			gsap.to(el, {
				width: needWidth,
				duration: tweenTime / 2,
				delay: immediate ? 0 : delayAppear,
			});

			showChild.classList.add('_active');

			let nextDelay = repeat ? 0 : delay + (immediate ? 0 : delayAppear);

			gsap.delayedCall(nextDelay, () => {
				next();
			});
		};

		resetVars();
		next(true);

		window.addEventListener('resize', (e) => {
			let activeChild = el.querySelector('._active'),
				updateWidth = activeChild.offsetWidth;

			gsap.set(el, { width: updateWidth });
		});


		/**
		 * Mouse to play the animation
		 * Only allow this if a mouseover element has been passed in
		 */
		if (mouseoverEl) {
			mouseoverEl.addEventListener('mouseover', (e) => {
				if (animating)
					return

				next(true, true);
			});
		}
	}
	update(debug) {
		document
			.querySelectorAll('[data-switch]')
			.forEach((el) => this.init(el, debug));
	}
}
export default new Switchers();
